.galleryBody {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.counterTops__container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 45px;
    gap: 70px 30px;
    background-color: var(--light-clr);
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.6); /* Adjust the alpha value (0.5) for the desired opacity */
    }
}

.counterTops__container .card {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 300px;
    max-width: 100%;
    height: 550px;
    margin: 10px;
    border-radius: 20px;
    transition: 0.5s;
    box-shadow: 0 35px 80px rgb(238, 229, 229);
}

/* .counterTops__container .card:hover {
    height: 450px;
} */

.counterTops__container .card .img-box {
    position: absolute;
    top: 20px;
    width: 250px;
    height: 300px;
    left:24px;
    border-radius: 12px;
    /* overflow: hidden; */
    transition: 0.5s;
}

/* .counterTops__container .card:hover .img-box {
    top: -50px;
    scale: 0.75;
    box-shadow: 0 15px 45px rgba(0, 0, 0, 0.2);
} */

.counterTops__container .card .img-box img {
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.counterTops__container .card .content {
    position: absolute;
    top: 330px;
    width: 100%;
    height: 250px;
    padding: 0 30px;
    text-align: center;
    /* overflow: hidden; */
    transition: 0.5s;
}

/* .counterTops__container .card:hover .content {
    top: 230px;
    height: 250px;
} */

.counterTops__container .card .content h2 {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--dark-clr);
}

.counterTops__container .card .content p {
    color: #333;
    font-size: 0.7rem;
    padding-bottom: 0;
}

.counterTops__container .card .content a {
    position: relative;
    top: 6px;
    display: inline-block;
    padding: 5px 10px;
    text-decoration: none;
    background: var(--dark-clr);
    color: var(--main-clr);
    font-weight: 500;
}

.counterTops__container .card .content a:hover {
    background-color: var(--main-clr);
    color: var(--dark-clr);
}

@media (max-width: 480px) {
    .counterTops__container .card {
        width: 380px;
        border-radius: 15px;
    }

    .counterTops__container .card .img-box {
        width: 320px;
        border-radius: 10px;
        left: 30px;
    }

    .counterTops__container .card .content p {
        font-size: 0.6rem;
    }

    .counterTops__container .card .content a {
        font-size: 0.7rem;
    }
    .video__details{
        font-size: 10px;
    }
}
.imageCard{
    height:"400px";
    /* border: 2px solid var(--dark-clr); */
    margin: 5px;
    border-radius: 10px;
    transform: scale(0.95);
    background-color: white;
    box-shadow: 5px 5px 50px var(--main-clr);
    transition: box-shadow 0.5s, transform 0.5s;
    text-align: center;
}
.imageCard div img{
    height: 180px;
    width: 200px;
}
.imageCard div p{
    width: 270px;
}
.imageCard:hover{
    transform: scale(1);
}
.imageCard__heading{
    color: var(--main-clr);
}
.offerLeftSection{
    position: relative;
  }
  .offerDetailsPart{
    width: 100%;
    height: 0%;
    background: rgba(68, 77, 77, 0.582);
    position: absolute;
    /* overflow: hidden; */
    bottom: 0;
    left: 0;
    right: 0;
    transition: 0.8s;
    cursor: pointer;
  }
  .overLay{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .offerLeftSection:hover .offerDetailsPart,.overLay{
  height: 100%;
  color: white;
  }