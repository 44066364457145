* {
    --main-font: "Poppins", sans-serif;
    --title-font: "Playfair Display", serif;
    --main-clr: #5E529C;
    --light-clr:#9a8aeb0c;
    --secondary-clr: #f1c52b;
    --dark-clr: #111;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  h1{
    color: var(--main-clr) !important;
    /* font-family: cursive !important; */
  }