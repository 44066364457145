section {
    padding: 60px 0;
    min-height: 100vh;
}
.contact-info {
display: inline-block;
width: 100%;
text-align: center;
margin-bottom: 10px;
}
.contact-info-icon {
margin-bottom: 15px;
}
.contact-info-item {
background: var(--main-clr);
padding: 30px 0px;
}
.contact-page-sec .contact-page-form h2 {
color: var(--main-clr);
text-transform: capitalize;
font-size: 22px;
font-weight: 700;
}
.contact-page-form .col-md-6.col-sm-6.col-xs-12 {
padding-left: 0;
}  
.contact-page-form.contact-form input {
margin-bottom: 5px;
}  
.contact-page-form.contact-form textarea {
height: 110px;
}
.contact-page-form.contact-form input[type="submit"] {
background: var(--main-clr);
width: 150px;
border-color: var(--main-clr);
}
.contact-info-icon i {
font-size: 48px;
color: var(--secondary-clr);
}
.contact-info-text p{margin-bottom:0px;}
.contact-info-text h2 {
color: #fff;
font-size: 22px;
text-transform: capitalize;
font-weight: 600;
margin-bottom: 10px;
}
.contact-info-text span {
color: #999999;
font-size: 16px;
font-weight: 400 ;
display: inline-block;
width: 100%;
}

.contact-page-form input , .contact-page-form select {
background: #f9f9f9 none repeat scroll 0 0;
border: 1px solid #f9f9f9;
margin-bottom: 20px;
padding: 12px 16px;
width: 100%;
border-radius: 4px;
}
.contact-page-form select {
    appearance: none; /* Remove default appearance */
    -webkit-appearance: none; /* Remove default appearance for older versions of Chrome and Safari */
    -moz-appearance: none; /* Remove default appearance for older versions of Firefox */
    padding-right: 40px; /* Ensure enough space for the arrow */
  }

  .contact-page-form select option {
    background: #f9f9f9;
    color: #000;
  }

.contact-page-form .message-input {
display: inline-block;
width: 100%;
padding-left: 0;
}
.single-input-field textarea {
background: #f9f9f9 none repeat scroll 0 0;
border: 1px solid #f9f9f9;
width: 100%;
height: 120px;
padding: 12px 16px;
border-radius: 4px;
}
.single-input-fieldsbtn input[type="submit"] {
background: var(--secondary-clr) none repeat scroll 0 0;
color: #fff;
display: inline-block;
font-weight: 600;
padding: 10px 0;
text-transform: capitalize;
width: 250px;
margin-top: 20px;
font-size: 16px;
}
.single-input-fieldsbtn input[type="submit"]:hover{background:#071c34;transition: all 0.4s ease-in-out 0s;border-color:#071c34}
.single-input-field  h4 {
color: #464646;
text-transform: capitalize;
font-size: 14px;
}
.contact-page-form {
display: inline-block;
width: 100%;
margin-top: 30px;
}

.contact-page-map {
margin-top: 36px;
}
.contact-page-form form {
padding: 20px 15px 0;
}